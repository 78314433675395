/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const searchResultStyles = makeStyles(() => ({
  root: {
    width: '100%',
    backgroundColor: '#ffffff',
    margin: '20px 0px',
    padding: '0px'
  },
  searchText: {
    '& span': {
      fontSize: '1.5em',
      color: '#000'
    }

  },
  query: {
    fontSize: '1em !important',
    fontWeight: 'normal'
  },

  searchResult: {
    fontSize: '1.5em'
  },
  dividerMargin: {
    marginBottom: '15px'
  }

}));

export const siteMap = makeStyles(() => ({
  underline: (data) => ({
    borderBottom: '1px dotted',
    fontFamily: `${data?.typography?.bodyFontFamily}` || 'Georgia, Arial, Helvetica, sans-serif',
    fontSize: `${data?.typography?.bodyFontSize}` || '1.15em',
    color: `${data?.typography?.footerLinkColor}` || '#004282'
  }),
  space: (data) => ({
    color: `${data?.typography?.footerLinkColor}` || '#004282',
    fontFamily: `${data?.typography?.bodyFontFamily}` || 'Georgia, Arial, Helvetica, sans-serif',
    padding: '5px'
  })
}));
